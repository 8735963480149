<template>
  <b-col lg="12">
    <b-card v-if="canViewThisAction('read', 'PurchaseRequest')">
      <b-card-title class="mb-1 fs-18 weight-500">
        <feather-icon
          icon="ListIcon"
          class="mr-75"
          size="20"
        />{{ widgetName }} ({{ totalRows }})
      </b-card-title>

      <b-table
        ref="refPurchaseRequestTable"
        class="position-relative event_list shadow-table"
        :items="tableItems"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :current-page.sync="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :sort-compare="sortCompare"
      >
        <template #cell(caseID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'PurchaseRequest')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id"
              :to="{ name: 'purchasing-purchase-request-show', params: { id: data.item._id } }"
            >
              {{ data.item.caseID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.caseID }}
            </b-button>
            <br>
            <span
              v-if="data.item.stage == 'in-order' || data.item.stage == 'in-delivery'"
              class="text-bold-black detail-view-id"
            >
              {{ data.item.prID }}
            </span>
          </div>
        </template>

        <template #cell(receivedDate)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.receivedDate ? dateFormatWithTime(data.item.receivedDate) : '' }}</span>
          </div>
        </template>

        <template #cell(purpose)="data">
          <div class="">
            <span class="">{{ data.item.purpose }}</span>
          </div>
        </template>

        <template #cell(requestedBy)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.requestedBy.name }}</span>
          </div>
        </template>

        <template #cell(vendor)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.vendor ? data.item.vendor.companyName : '' }}</span>
          </div>
        </template>

        <template #cell(company)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.company ? data.item.company.name : '' }}</span>
          </div>
        </template>

        <template #cell(invoices)="data">
          <div
            v-for="invoice in data.item.invoices"
            :key="invoice._id"
            class="text-nowrap"
          >
            <div v-if="canViewThisAction('show', 'VendorPayment')">
              <b-button
                variant="link"
                class="p-0"
                :to="{ name: 'purchasing-vendor-payment-single-view', params: { id: invoice._id } }"
              >
                {{ invoice.invoiceID }}
              </b-button>
            </div>
            <div v-else>
              <span>{{ invoice.invoiceID }}</span>
            </div>
          </div>
        </template>

        <template #cell(pendingSignatory)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.pendingSignatory ? data.item.pendingSignatory.name : '-' }}</span>
          </div>
        </template>

        <template #cell(assignedTo)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.assignedTo ? data.item.assignedTo.name : '' }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(stage)="data">
          <b-badge
            :variant="`light-${resolvePurchaseRequestStageVariant(data.item.stage)}`"
            class="text-capitalize"
          >
            <feather-icon
              :icon="resolveRequestStageIcon(data.item.stage)"
              size="24"
              class="align-middle text-body"
            />
            {{ resolveRequestStageName(data.item.stage) }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolvePurchaseRequestStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(budgetStatus)="data">
          <div v-if="data.item.budgetStatus">
            <b-badge
              :variant="`light-${data.item.budgetStatus == 'allocated' ? 'success' : (data.item.budgetStatus == 'not-allocated' ? 'warning' : 'danger')}`"
              class="text-capitalize-first"
            >
              {{ data.item.budgetStatus }}
            </b-badge>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #head(freshness)="">
          <span class="">Freshness ({{ dateOption }})</span>
        </template>

        <template #cell(freshness)="data">
          <span class="text-bold-black">{{ dateOption == 'Created On' ? dateDifference(data.item.createdAt) : dateDifference(data.item.updatedAt) }}</span>
        </template>
        <template #custom-foot>
          <b-tr>
            <b-td :colspan="tableColumns.length">
              <div class="d-flex justify-content-between w-100">
                <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-td>
          </b-tr>
        </template>
      </b-table>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BCardTitle, BCol, BTable, BButton, VBTooltip, BPagination, BBadge, BTr, BTd,
} from 'bootstrap-vue'
import store from '@/store/index'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardTitle,
    BCol,
    BTable,
    BButton,
    BPagination,
    BBadge,
    BTr,
    BTd,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    widgetName: {
      type: [String, null],
      default: '',
    },
    budgetStatusOptions: {
      type: [Array, null],
      default: () => [],
    },
    requestType: {
      type: [Array, null],
      default: () => [],
    },
    filterOption: {
      type: [String, null],
      default: '',
    },
    dateOption: {
      type: [String, null],
      default: '',
    },
    dateRangeOption: {
      type: [Array, null],
      default: () => [],
    },
    tableColumns: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  computed: {
    dataMeta() {
      let totalPage = 1
      let localItems = 0
      if (this.totalRows) {
        totalPage = Math.ceil(this.totalRows / this.perPage)
        if (totalPage === this.currentPage) {
          localItems = this.totalRows - (this.perPage * (this.currentPage - 1))
        } else {
          localItems = 10
        }
      }
      return {
        from: this.perPage * (this.currentPage - 1) + (this.totalRows ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItems,
        of: this.totalRows,
      }
    },
  },

  watch: {
    $props: {
      handler() {
        this.getData()
      },
      deep: true,
      immediate: true,
    },
  },

  beforeMount() {
    this.getData()
  },

  methods: {
    canViewThisAction,
    getData() {
      const formData = new FormData()
      formData.append('filterOption', this.filterOption)
      formData.append('dateOption', this.dateOption)
      formData.append('sortBy', this.sortBy)
      formData.append('sortDesc', this.sortDesc)
      formData.append('dateRangeOption', JSON.stringify(this.dateRangeOption))
      formData.append('budgetStatusOptions', JSON.stringify(this.budgetStatusOptions))
      formData.append('requestType', JSON.stringify(this.requestType))

      this.$http.post('user/purchasing/purchase-request/find', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const { purchaseRequests } = response.data
          this.tableItems = purchaseRequests
          this.totalRows = purchaseRequests.length
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolvePurchaseRequestStageVariant(stage) {
      if (stage === 'in-request') return 'danger'
      if (stage === 'in-quotation') return 'danger'
      if (stage === 'in-order') return 'danger'
      if (stage === 'in-delivery') return 'danger'
      return 'primary'
    },
    resolveRequestStageIcon(stage) {
      let icon = 'AlignCenterIcon'
      if (stage === 'in-request') {
        icon = 'BoxIcon'
      } else if (stage === 'in-quotation') {
        icon = 'ClipboardIcon'
      } else if (stage === 'in-order') {
        icon = 'BoxIcon'
      } else if (stage === 'in-delivery') {
        icon = 'TruckIcon'
      }

      return icon
    },
    resolveRequestStageName(stage) {
      let name = 'PR'
      if (stage === 'in-request') {
        name = 'PR'
      } else if (stage === 'in-quotation') {
        name = 'QCF'
      } else if (stage === 'in-order') {
        name = 'PO'
      } else if (stage === 'in-delivery') {
        name = 'DR'
      }

      return name
    },
    resolvePurchaseRequestStatusVariant(status) {
      if (status === 'draft') return 'secondary'
      if (status === 'pending approval') return 'warning'
      if (status === 'pending edit') return 'warning'
      if (status === 'verifying') return 'info'
      if (status === 'verified') return 'success'
      if (status === 'cancelled') return 'danger'
      if (status === 'completed') return 'success'
      return 'primary'
    },
    dateDifference(date) {
      const timezone = process.env.VUE_APP_TIME_ZONE
      const appointmentDate = moment(date).utcOffset(timezone)
      const today = moment().utcOffset(timezone)

      const difference = appointmentDate.diff(today, 'days')

      if (difference === 0) return 'Today'
      if (difference === 1) return '1 day later'
      if (difference === -1) return '1 day ago'
      if (difference > 1) return `${difference} days later`
      if (difference < -1) return `${Math.abs(difference)} days ago`

      return ''
    },
    // eslint-disable-next-line consistent-return
    sortCompare(aRow, bRow, key) {
      if (key === 'freshness') {
        if (this.dateOption === 'Created On') {
          // eslint-disable-next-line no-nested-ternary
          return aRow.createdAt < bRow.createdAt ? -1 : aRow.createdAt > bRow.createdAt ? 1 : 0
        }

        // eslint-disable-next-line no-nested-ternary
        return aRow.updatedAt < bRow.updatedAt ? -1 : aRow.updatedAt > bRow.updatedAt ? 1 : 0
      }
    },
  },
}
</script>
